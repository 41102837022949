(function ($) {
  $(document).ready(function () {
    // Ajax form submit / Аякс форма настраивается тут
    $(".ajax-form").on("submit", function (e) {
      e.preventDefault();
      const url = $(this).attr("action");
      const method = $(this).attr("method");
      const dataType = $(this).data("type") || null;
      const serialized = $(this).serialize();
      const self = $(this);

      $.ajax({
        url,
        type: method,
        dataType: dataType,
        data: {
          action: "ajaxForm",
          serialized,
        },
        success: function (data) {
          // Clear inputs
          self.find("input, textarea").val("");

          // Open thanks popup
          $(".overlay-cdk").removeClass("active");
          $("body").addClass("overflow-hidden");
          $("#thanksPopup").addClass("active");
        },
        error: function (data) {
          // Basic error handling
          alert("Ошибка, повторите позднее");
          console.error(data);
        },
      });
    });

    // Product counter
    // Счетчик продуктов
    $(".product-page__price-counter-btn.plus").on("click", function () {
      let $parent = $(this).closest(".product-page__price-counter-wrap");
      let $counter = $parent.find(".product-page__price-counter-input");
      let val = Number($counter.val());

      $counter.val(val + 1);
    });
    $(".product-page__price-counter-btn.minus").on("click", function () {
      let $parent = $(this).closest(".product-page__price-counter-wrap");
      let $counter = $parent.find(".product-page__price-counter-input");
      let val = Number($counter.val());

      if (val <= 1) {
        return;
      }
      $counter.val(val - 1);
    });

    // Number input arrows
    // Счетчик полей ввода цифр (input[type="number"])
    $(".custom-number-input__btn-up").on("click", function () {
      let $parent = $(this).closest(".custom-number-input-wrap");
      let $counter = $parent.find(".custom-number-input");
      let val = Number($counter.val());

      $counter.val(val + 1);
    });
    $(".custom-number-input__btn-down").on("click", function () {
      let $parent = $(this).closest(".custom-number-input-wrap");
      let $counter = $parent.find(".custom-number-input");
      let val = Number($counter.val());

      $counter.val(val - 1);
    });

    // Popups
    // Попапы
    $(".open-thanks-popup").on("click", function (e) {
      e.preventDefault();
      $(".overlay-cdk").removeClass("active");

      $("body").addClass("overflow-hidden");
      $("#thanksPopup").addClass("active");
    });

    $(".open-contact-form-popup").on("click", function (e) {
      e.preventDefault();
      $(".overlay-cdk").removeClass("active");

      $("body").addClass("overflow-hidden");
      $("#contactFormPopup").addClass("active");
    });

    $(".open-order-form-popup").on("click", function (e) {
      e.preventDefault();
      $(".overlay-cdk").removeClass("active");

      $("body").addClass("overflow-hidden");
      $("#orderFormPopup").addClass("active");
    });

    // Close overlay on outside click
    $(".overlay-cdk").on("click", function (e) {
      if (e.target !== e.currentTarget) return;

      $(this).removeClass("active");
      $("body").removeClass("overflow-hidden");
    });

    // Close overlay on button click
    $(".overlay-cdk__close-btn").on("click", function (e) {
      $(".overlay-cdk").removeClass("active");
      $("body").removeClass("overflow-hidden");
    });

    // Search bar
    $(".search-form__input").on("click focus", function () {
      $(this).closest(".search-form-wrap").addClass("active");
    });
    $(".search-form__input").on("", function () {
      $(this).closest(".search-form-wrap").removeClass("active");
    });

    $("body").on("click", function (e) {
      if ($(e.target).hasClass("search-form__input")) return;
      $(".search-form-wrap").removeClass("active");
    });

    if ($(".hero-swiper").length) {
      $(".hero-swiper").each(function () {
        const arrowPrev = $(this)
          .closest(".hero-swiper-wrap")
          .find(".hero-swiper-button-prev")[0];

        const arrowNext = $(this)
          .closest(".hero-swiper-wrap")
          .find(".hero-swiper-button-next")[0];

        new Swiper($(this)[0], {
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          navigation: {
            nextEl: arrowNext,
            prevEl: arrowPrev,
          },
          slidesPerView: 1,
          spaceBetween: 20,
          on: {
            init: function () {
              let total = this.slides.length;
              let current = this.activeIndex + 1;

              $(this.$el)
                .find(".hero-swiper__number-current-value")
                .html(current);
              $(this.$el).find(".hero-swiper__number-total-value").html(total);
            },
            slideChange: function () {
              let total = this.slides.length;
              let current = this.activeIndex + 1;

              $(this.$el)
                .find(".hero-swiper__number-current-value")
                .html(current);
              $(this.$el).find(".hero-swiper__number-total-value").html(total);
            },
          },
        });
      });
    }

    if ($(".posts-swiper").length) {
      $(".posts-swiper").each(function () {
        const arrowPrev = $(this)
          .closest(".posts-swiper-wrap")
          .find(".swiper-button-prev")[0];

        const arrowNext = $(this)
          .closest(".posts-swiper-wrap")
          .find(".swiper-button-next")[0];

        new Swiper($(this)[0], {
          navigation: {
            nextEl: arrowNext,
            prevEl: arrowPrev,
          },
          slidesPerView: 1,
          spaceBetween: 20,
          breakpoints: {
            576: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
            992: {
              slidesPerView: 4,
            },
          },
        });
      });
    }

    // Pushbar (mobile sidebar)
    const pushbar = new Pushbar({
      blur: true,
      overlay: true,
    });

    // On scroll events
    $(document).on("scroll", function () {
      if ($(".main-header").length) {
        let sticky = $(".main-header__top").outerHeight(true);
        if (window.pageYOffset > sticky) {
          $(".main-header__bottom").addClass("sticky");
          // $(".main-header")
          //   .find(".navbar")
          //   .removeClass("navbar-dark")
          //   .addClass("navbar-light");
        } else {
          $(".main-header__bottom").removeClass("sticky");
        }
      }
    });

    if ($(".main-header").length) {
      let sticky = $(".main-header__top").outerHeight(true);
      if (window.pageYOffset > sticky) {
        $(".main-header__bottom").addClass("sticky");
        // $(".main-header")
        //   .find(".navbar")
        //   .removeClass("navbar-dark")
        //   .addClass("navbar-light");
      } else {
        $(".main-header__bottom").removeClass("sticky");
      }
    }
  });
})(jQuery);

// Custom select input
var x, i, j, l, ll, selElmnt, a, b, c;
/*look for any elements with the class "custom-select":*/
x = document.getElementsByClassName("custom-select-wrap");
l = x.length;
for (i = 0; i < l; i++) {
  selElmnt = x[i].getElementsByTagName("select")[0];
  ll = selElmnt.length;
  /*for each element, create a new DIV that will act as the selected item:*/
  a = document.createElement("DIV");
  a.setAttribute("class", "select-selected");
  a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
  x[i].appendChild(a);
  /*for each element, create a new DIV that will contain the option list:*/
  b = document.createElement("DIV");
  b.setAttribute("class", "select-items select-hide");
  for (j = 1; j < ll; j++) {
    /*for each option in the original select element,
    create a new DIV that will act as an option item:*/
    c = document.createElement("DIV");
    c.innerHTML = selElmnt.options[j].innerHTML;
    c.addEventListener("click", function (e) {
      /*when an item is clicked, update the original select box,
        and the selected item:*/
      var y, i, k, s, h, sl, yl;
      s = this.parentNode.parentNode.getElementsByTagName("select")[0];
      sl = s.length;
      h = this.parentNode.previousSibling;
      for (i = 0; i < sl; i++) {
        if (s.options[i].innerHTML == this.innerHTML) {
          s.selectedIndex = i;
          h.innerHTML = this.innerHTML;
          y = this.parentNode.getElementsByClassName("same-as-selected");
          yl = y.length;
          for (k = 0; k < yl; k++) {
            y[k].removeAttribute("class");
          }
          this.setAttribute("class", "same-as-selected");
          break;
        }
      }
      h.click();
    });
    b.appendChild(c);
  }
  x[i].appendChild(b);
  a.addEventListener("click", function (e) {
    /*when the select box is clicked, close any other select boxes,
      and open/close the current select box:*/
    e.stopPropagation();
    closeAllSelect(this);
    this.nextSibling.classList.toggle("select-hide");
    this.classList.toggle("select-arrow-active");
  });
}
function closeAllSelect(elmnt) {
  /*a function that will close all select boxes in the document,
  except the current select box:*/
  var x,
    y,
    i,
    xl,
    yl,
    arrNo = [];
  x = document.getElementsByClassName("select-items");
  y = document.getElementsByClassName("select-selected");
  xl = x.length;
  yl = y.length;
  for (i = 0; i < yl; i++) {
    if (elmnt == y[i]) {
      arrNo.push(i);
    } else {
      y[i].classList.remove("select-arrow-active");
    }
  }
  for (i = 0; i < xl; i++) {
    if (arrNo.indexOf(i)) {
      x[i].classList.add("select-hide");
    }
  }
}
/*if the user clicks anywhere outside the select box,
then close all select boxes:*/
document.addEventListener("click", closeAllSelect);
